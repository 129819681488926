import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import api from "../services/config.url";
import showPopup from "../components/PopUp";
//Components
import TableBooks from "./../components/myBooks/booksTable";
import Score from "./../components/myBooks/Score";
import PageTitle from "./../layouts/PageTitle";
import Raffle from "./../components/raffle";

function MyBooks() {
  const listRef = useRef(null);
  const { scroll } = useParams();
  const [canScroll, setCanScroll] = useState(null);

  useEffect(() => {
    try {
      api.get(`/customers/revisions-book/check-revisions`);
    } catch (error) {
     console.log(error)
    }
  }, []);

  useEffect(() => {
    try {
      api.get(`/customers/raffles/check-customer-book`);
    } catch (error) {
      console.log(error)
    }
  }, []);

  useEffect(() => {
    if (scroll !== undefined || scroll) {
      const targetElement = document.getElementById("targetElement");

      if (targetElement) {
        // targetElement.scrollIntoView({ behavior: "smooth" });
        listRef.current?.lastElementChild?.scrollIntoView();
        setCanScroll("false");
      }
    } else {
      setCanScroll("true");
    }
  }, [canScroll]);

  const ContentBody = () => {
    return (
      <div className="page-content">
        <PageTitle parentPage="CJ" childPage="Meus estudos" />
        <section className="content-inner shop-account">
          <div className="container">
            <div className="row mb-5">
              <div className="col-12">
                <Score />
                <Raffle />
                <div ref={listRef} style={{ marginTop: "100px" }}>
                  <TableBooks />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  };

  return (
    <div className="header-padding-main">
      <ContentBody />
    </div>
  );
}
export default MyBooks;
