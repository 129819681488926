import React, { useEffect, useContext, useState, useMemo } from "react";
import {
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from "@coreui/react";
import api from "../services/config.url";
import showPopup from "../components/PopUp";
import AuthContext from "../contexts/auth";

const BooksAcquiredList = (props) => {
    const { cart, setLoading, setSizeCart, user } = useContext(AuthContext);
    const [orders, setOrders] = useState([]);
    const sizeCart = useMemo(() => cart?.items?.length, [cart]);
    const [isLoadingContainer, setIsLoadingContainer] = useState(false);
    
    useEffect(() => {
      if (!cart) return;
      setSizeCart(sizeCart);
      setIsLoadingContainer(true);
      const fetchData = async () => {
        try {
          const response = await api.get(
            `/customers/orders/customer-products`
          );
          setOrders(response.data.items);
        } catch (error) {
          const BackendError = error?.response?.data?.message;
          const genericError = "Erro ao listar os cadernos adquiridos";
          showPopup(BackendError || genericError, "error");
        } finally {
          setIsLoadingContainer(false);
        }
      };
      fetchData();
    }, [cart]);
  
    const handleDownloadCloud = async (obj) => {
        const sku = obj.sku || obj?.parent?.sku;
        const { name, secondName, document: documentCustomer } = user || {};
  
      if (!name) {
        showPopup(
          "O campo Nome é obrigatório! Acesse seu perfil para preenchê-lo.",
          "error"
        );
        return;
      }
      if (!secondName) {
        showPopup(
          "O campo Sobrenome é obrigatório! Acesse seu perfil para preenchê-lo.",
          "error"
        );
        return;
      }
      if (!documentCustomer) {
        showPopup(
          "O campo CPF é obrigatório! Acesse seu perfil para preenchê-lo.",
          "error"
        );
        return;
      }
      try {
        setLoading(true);
        const response = await api.post(
          `/customers/products/cloud-pdf`,
          {
            sku,
            customerName: `${name} ${secondName}`,
            customerCPF: documentCustomer,
          },
          { "Content-Type": "application/pdf" }
        );
        const pdfUrl = response.data.downloadUrl;
        fetch(pdfUrl)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Erro ao baixar o arquivo");
            }
            return response.blob();
          })
          .then((blob) => {
            saveAs(blob, `${response.data.fileName}.pdf`);
          })
          .catch((error) => {
            console.error("Erro:", error);
          });
      } catch (error) {
        const BackendError = error?.response?.data?.message;
        const genericError = "Erro ao baixar os arquivos";
        showPopup(BackendError || genericError, "error");
      } finally {
        setLoading(false);
      }
    };
  return (
    <>
   <CAccordion>
        <CAccordionItem itemKey={1}>
          <div className="table-responsive">
            <div className="table check-tbl">
              <CAccordionHeader className="bg-my-books-collapse">
                <div className="title-of-table ">
                  <div>MEUS CADERNOS</div>
                </div>
              </CAccordionHeader>
              <CAccordionBody className="p-0">
                <div className="lines-of-table">
                  {orders && orders?.length < 1 ? (
                    !isLoadingContainer ? (
                      <div className="p-3">
                        Você não possui nenhum pedido com status de pagamento
                        aprovado.
                      </div>
                    ) : (
                      <div className="loadingContainer m-3"></div>
                    )
                  ) : (
                    <CAccordion>
                      {orders.map((order, index) => (
                        <CAccordionItem
                          itemKey={index}
                          key={index}
                          className="p-0"
                        >
                          <React.Fragment key={index}>
                            <CAccordionHeader
                              className={
                                (order?.parent.items?.length < 1)
                                  ? "removeIcon espacinho"
                                  : ""
                              }
                            >
                              <div className="d-flex justify-content-between w-100">
                                <div>
                                  {order?.parent ? (
                                    <React.Fragment>
                                      {order?.parent?.sku} -{" "}
                                      {order.parent?.name}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <div
                                        style={{
                                          marginBottom: "9px",
                                          marginTop: "4px",
                                        }}
                                      >
                                        {order?.parent.name}
                                      </div>
                                    </React.Fragment>
                                  )}
                                </div>
                                <div className="ml-auto m-r20 p-l10">
                                  {(
                                    !order?.parent?.items ||
                                    order?.parent?.items?.length < 1) && (
                                    <input
                                      type="button"
                                      onClick={() => handleDownloadCloud(order.parent)}
                                      className="d-flex justify-content-center downloadPdfBtn btn btn-primary btnhover max-w100 p-2"
                                      target="_blank"
                                      style={{ alignItems: "center" }}
                                      value={"Download"}
                                    />
                                  )}
                                </div>
                              </div>
                            </CAccordionHeader>
                            <CAccordionBody className="p-0">
                              {order?.parent?.items && order?.parent?.items.length > 0 ? (
                                <React.Fragment>
                                  {order?.parent?.items?.map((child, index) => (
                                    <React.Fragment key={index}>
                                      <div className="p-3 flex-my-books">
                                        <div className="product-item-name item-1">
                                          {child?.parent?.parent ? (
                                            <React.Fragment>
                                              {child?.sku} - {child?.name}
                                            </React.Fragment>
                                          ) : (
                                            <React.Fragment>
                                              {child?.sku} - {child?.name}
                                            </React.Fragment>
                                          )}
                                        </div>
                                        <div className="item-3">
                                          <input
                                            type="button"
                                            onClick={() =>
                                              handleDownloadCloud(child)
                                            }
                                            className="d-flex justify-content-center downloadPdfBtn btn btn-primary btnhover max-w100 p-2"
                                            target="_blank"
                                            value={"Download"}
                                          />
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  ))}
                                </React.Fragment>
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                            </CAccordionBody>
                          </React.Fragment>
                        </CAccordionItem>
                      ))}
                    </CAccordion>
                  )}
                </div>
              </CAccordionBody>
            </div>
            <div></div>
          </div>
        </CAccordionItem>
      </CAccordion>
    </>
  );
};

export default BooksAcquiredList;
