import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from "@coreui/react";
import ModalMetas from "../../components/modal/modalMetas";
import ModalSpots from "../../components/modal/modalSpots";
import ModalControl from "../../components/modal/modalControl";
import ModalActions from "../../components/modal/modalActions";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import api from "../../services/config.url";
import showPopup from "../../components/PopUp";
import AuthContext from "../../contexts/auth";
import { TimePicker } from "antd";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import "moment/locale/pt-br";
const format = "HH:mm";
import dayjs from "dayjs";

export default function Score() {
  const { setLoading } = useContext(AuthContext);
  const [openGoals, setOpenGoals] = useState(false);
  const [openSpots, setOpenSpots] = useState(false);
  const [calendarId, setCalendarId] = useState(null);
  const [openControlModal, setOpenControlModal] = useState(false);
  const [openActionsModal, setOpenActionsModal] = useState(false);
  const [historic, setHistoric] = useState([]);
  const [punctuation, setPunctuation] = useState([]);
  const [week, setWeek] = useState([]);
  const [value, setValue] = useState("1");
  const [currentSpotsSelected, setCurrentSpotsSelected] = useState([]);
  const [currentGoalsSelected, setCurrentGoalsSelected] = useState([]);
  const [internalSpots, setInternalSpots] = useState([]);
  const [internalGoal, setInternalGoal] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState({
    current: 1,
    pageSize: 8,
  });

  const encodedQuery = Object.keys(query)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
    )
    .join("&");

  const handlePagination = (page) => {
    setCurrentPage(page);
    setQuery({
      ...query,
      current: page,
    });
  };
  const fetchData = async (route) => {
    try {
      setLoading(true);
      const response = await api.get(route);
      if (route === `/customers/punctuation/historic?${encodedQuery}`) {
        setHistoric(response.data);
      } else {
        const { panelGoals } = response.data;

        const internalGoalsData = panelGoals?.map((goal) => {
          return {
            nameOfWeek: goal.nameOfWeek,
            goalCad: goal.goalCad,
            goalInfo: goal.goalInfo,
            goalQc: goal.goalQc,
            sumTotalInternalGoals: goal.sumTotalInternalGoals,
          };
        });
        const internalSpotsData = panelGoals?.map((spots) => {
          return {
            nameOfWeek: spots.nameOfWeek,
            cad: spots.cad,
            info: spots.info,
            qc: spots.qc,
            sumTotalInternalSpots: spots.sumTotalInternalSpots,
          };
        });

        setWeek(response.data.daysWeek);
        setPunctuation(response.data);
        setInternalSpots(internalSpotsData);
        setInternalGoal(internalGoalsData);
        setCalendarId(response.data._id);
      }
    } catch (error) {
      let message = { message: error?.response?.data.message, type: "error" };

      if (message.message === "Unauthorized") {
        message = {
          message: "Você precisar estar logado",
          type: "Alert",
        };
      }
      showPopup(message, message.type);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (openActionsModal) return;
    fetchData(`/customers/punctuation/historic?${encodedQuery}`);
  }, [openActionsModal, currentPage]);

  useEffect(() => {
    if (openActionsModal) return;
    fetchData("/customers/punctuation");
  }, [openActionsModal]);

  const tipsPopoverOne = (props) => (
    <Tooltip
      id="button-tooltip"
      {...props}
      style={{
        position: "absolute",
        zIndex: 9999999,
        width: "max-content",
        fontSize: "14px",
        textAlign: "justify",
        backgroundColor: "#000",
        padding: "10px 10px",
        color: "red",
        borderRadius: 3,
        ...props.style,
      }}
    >
      <div style={{ textAlign: "justify" }}>
        Aqui você irá preencher o Cadernos, os Informativos e as Questões
        estudadas no dia. Estes registros são muito importantes para controlar
        suas revisões e sua evolução nos estudos.
      </div>
    </Tooltip>
  );
  const tipsPopoverTwo = (props) => (
    <Tooltip
      id="button-tooltip"
      {...props}
      style={{
        position: "absolute",
        zIndex: 99,
        width: "max-content",
        minWidth: "320px",
        fontSize: "14px",
        textAlign: "justify",
        backgroundColor: "#000",
        padding: "10px 10px",
        color: "red",
        borderRadius: 3,
        ...props.style,
      }}
    >
      <div style={{ textAlign: "justify", width: "100%", minWidth: "100%" }}>
        <p>
          Aqui você irá preencher a sua <strong> pontuação diária. </strong>
        </p>
        <p>
          <strong> CAD : </strong> marcar <strong> 1 ponto </strong> por cada{" "}
          <strong> página </strong> de Caderno, lei ou doutrina.
        </p>
        <p>
          <strong> INFO: </strong> marcar <strong> 1 ponto </strong> por cada{" "}
          <strong> página </strong> de Informativo.
        </p>
        <p>
          <strong> QC: </strong> marcar <strong> 1 ponto</strong> por questão de{" "}
          <strong> múltipla escolha, e 0,5 </strong> por questão{" "}
          <strong> “certo ou errado”. </strong>
        </p>
        Se estudar por <strong> vídeos ou áudios: </strong> marcar{" "}
        <strong> 1 ponto </strong> a cada <strong> 10 minutos. </strong>
        Ao final da semana você verá o total de pontos realizados, e no final de
        domingo, este histórico semanal é registrado automaticamente. O seu
        objetivo é manter-se constante nesta pontuação semanal!
      </div>
    </Tooltip>
  );
  const tipsPopoverThree = (props) => (
    <Tooltip
      id="button-tooltip"
      {...props}
      style={{
        position: "absolute",
        zIndex: 99,
        width: "max-content",
        fontSize: "14px",
        textAlign: "justify",
        backgroundColor: "#000",
        padding: "10px 10px",
        color: "red",
        borderRadius: 3,
        ...props.style,
      }}
    >
      <div style={{ textAlign: "justify" }}>
        Aqui você irá preencher a{" "}
        <strong> meta de sua pontuação diária, </strong> em CAD, INFO e QC. Obs:
        a média dos estudantes em geral é de 10 pontos/hora. A quantidade deve
        andar junto com a qualidade. Os números não são o foco, mas apenas uma
        referência, para você ir melhorando a cada dia.
      </div>
    </Tooltip>
  );
  const tipsPopoverFour = (props) => (
    <Tooltip
      id="button-tooltip"
      {...props}
      style={{
        position: "absolute",
        zIndex: 99,
        width: "max-content",
        fontSize: "14px",
        textAlign: "justify",
        backgroundColor: "#000",
        padding: "10px 10px",
        color: "red",
        borderRadius: 3,
        ...props.style,
      }}
    >
      <div style={{ textAlign: "justify" }}>
        Aqui você irá preencher as horas e minutos que conseguiu dedicar, em
        cada dia, para o estudo de CAD, INFO e QC.
      </div>
    </Tooltip>
  );
  const tipsPopoverFive = (props) => (
    <Tooltip
      id="button-tooltip"
      {...props}
      style={{
        position: "absolute",
        zIndex: 99,
        maxidth: "max-content",
        fontSize: "14px",
        textAlign: "justify",
        backgroundColor: "#000",
        padding: "10px 10px",
        color: "red",
        borderRadius: 3,
        ...props.style,
      }}
    >
      <div style={{ textAlign: "justify" }}>
        Aqui você irá preencher a
        <strong> meta de horas de estudo que pretende dedicar </strong>
        para CAD, INFO e QC. A quantidade deve andar junto com a qualidade. Os
        números não são o foco, mas apenas uma referência, para você ir
        melhorando a cada dia
      </div>
    </Tooltip>
  );

  const updateWeek = (day, type) => {
    const payload = {};
    if (type === "externalSpots") {
      payload.externalSpots = {
        nameOfWeek: day.nameOfWeek,
        cad: Number(day.cad),
        info: Number(day.info),
        qc: Number(day.qc),
      };
    }

    if (type === "externalGoals") {
      payload.externalGoals = {
        nameOfWeek: day.nameOfWeek,
        goalCad: day.goalCad,
        goalInfo: day.goalInfo,
        goalQc: day.goalQc,
      };
    }
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await api.patch(
          `/customers/punctuation/${punctuation._id}`,
          payload
        );
        setPunctuation(response.data);
        setWeek(response.data.daysWeek);
      } catch (error) {
        const BackendError = error?.response?.data?.message;
        const genericError = "Erro ao editar as pontuações";
        showPopup(BackendError || genericError, "error");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  };

  const toHour = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours < 10 ? "0" + hours : hours}:${
      remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes
    }h`;
  };

  const toMinutes = (value) => {
    if (!value) return 0;
    if (value === 0 || value?.length <= 3) return 0;
    if (typeof value === "string") {
      const [hours, minutes] = value.split(":");
      return parseInt(hours) * 60 + parseInt(minutes);
    }
  };

  const handdleModalSpots = (currentSpots) => {
    setCurrentSpotsSelected(currentSpots);
    setOpenSpots(true);
  };

  const handleCloseSpots = async () => {
    setOpenSpots(false);
    setCurrentSpotsSelected([]);
    await fetchData("/customers/punctuation");
  };
  const handdleModalGoals = (currentGoals) => {
    setCurrentGoalsSelected(currentGoals);
    setOpenGoals(true);
  };

  const handleCloseGoals = async () => {
    setOpenGoals(false);
    setCurrentGoalsSelected([]);
    await fetchData("/customers/punctuation");
  };

  const PunctuationWeek = () => {
    if (!punctuation || !week || !internalGoal || !internalSpots) return;
    const {
      lastCad,
      lastInfo,
      lastQc,
      lastGoalCad,
      lastGoalInfo,
      lastGoalQc,
      lastTotalGoal,
      lastTotal,
      lastSpotsInternalCad,
      lastSpotsInternalInfo,
      lastSpotsInternalQc,
      lastGoalsInternalCad,
      lastGoalsInternalInfo,
      lastGoalsInternalQc,
      lastWeekSumTotalInternalGoals,
      lastWeekSumTotalInternalSpots,
    } = punctuation;

    let arrWeek = {
      daysWeek: [week],
      internalGoal,
      internalSpots,
      lastCad,
      lastInfo,
      lastQc,
      lastGoalCad,
      lastGoalInfo,
      lastGoalQc,
      lastTotalGoal,
      lastTotal,
      lastSpotsInternalCad,
      lastSpotsInternalInfo,
      lastSpotsInternalQc,
      lastGoalsInternalCad,
      lastGoalsInternalInfo,
      lastGoalsInternalQc,
      lastWeekSumTotalInternalGoals,
      lastWeekSumTotalInternalSpots,
    };

    return (
      <>
        {/*  DIAS DA SEMANA */}
        {arrWeek.daysWeek[0].map((day, index) => (
          <div key={index} className="box-week">
            <>
              <div className="score-top t-center">
                <p className="m-0">
                  {" "}
                  {moment(day.date).format("D [de] MMMM [de] YYYY")}{" "}
                </p>
                <p className="font-weight-600"> {day.nameOfWeek} </p>
              </div>
              <div className="detail-week">
                <div className="box-one-detail t-center b-gray">
                  {/*  ROW 1 */}
                  <div className="style-of-datail">
                    <p
                      className="m-0 font-weight-600 btn-skin"
                      onClick={() => {
                        setValue("1");
                        setOpenControlModal(true);
                      }}
                    >
                      {" "}
                      CAD{" "}
                    </p>
                  </div>
                  {/*  ROW 2 */}
                  <div className="input-container">
                    <input
                      className="t-center font-weight-600"
                      defaultValue={`${day.cad}`}
                      onChange={(e) => (day.cad = e.target.value)}
                      type="text"
                      onBlur={() => updateWeek(day, "externalSpots")}
                    />
                  </div>
                  {/*  ROW 3 */}
                  <p
                    className="m-0 btn-skin btn-skin-modal"
                    onClick={() => handdleModalSpots(internalSpots[index])}
                  >
                    <span className="meta-button">Meta:</span>
                    {arrWeek.internalSpots[index]?.cad || 0}
                    {""}
                  </p>
                  {/*  ROW 4 */}
                  <div className="icon-hrs">
                    <TimePicker
                      defaultValue={dayjs(toHour(day?.goalCad || 0), format)}
                      inputReadOnly={true}
                      format={format}
                      showNow={false}
                      suffixIcon={""}
                      onBlur={(e) =>
                        updateWeek(
                          { ...day, goalCad: toMinutes(e.target.value) },
                          "externalGoals"
                        )
                      }
                      className="timer mr-10"
                      size="small"
                    />
                  </div>
                  {/*  ROW 5 */}
                  <p
                    className="m-0 btn-skin btn-skin-modal"
                    onClick={() => handdleModalGoals(internalGoal[index])}
                  >
                    <span className="meta-button">Meta:</span>
                    {toHour(arrWeek.internalGoal[index]?.goalCad)}{" "}
                  </p>
                </div>
                <div className="box-one-detail t-center b-gray">
                  {/*  ROW 1 */}
                  <div className="style-of-datail">
                    <p
                      className="m-0 font-weight-600 btn-skin"
                      onClick={() => {
                        setValue("2");
                        setOpenControlModal(true);
                      }}
                    >
                      {" "}
                      INFO{" "}
                    </p>
                  </div>
                  {/*  ROW 2 */}
                  <div className="input-container">
                    <input
                      className="t-center font-weight-600"
                      defaultValue={day.info}
                      onChange={(e) => (day.info = e.target.value)}
                      type="text"
                      onBlur={() => updateWeek(day, "externalSpots")}
                    />
                  </div>
                  {/*  ROW 3 */}
                  <p
                    className="m-0 btn-skin btn-skin-modal"
                    onClick={() => handdleModalSpots(internalSpots[index])}
                  >
                    <span className="meta-button">Meta:</span>
                    {arrWeek.internalSpots[index]?.info || 0}
                    {""}
                  </p>
                  {/*  ROW 4 */}
                  <div className="icon-hrs">
                    <TimePicker
                      defaultValue={dayjs(toHour(day?.goalInfo || 0), format)}
                      inputReadOnly={true}
                      format={format}
                      showNow={false}
                      suffixIcon={""}
                      onBlur={(e) =>
                        updateWeek(
                          { ...day, goalInfo: toMinutes(e.target.value) },
                          "externalGoals"
                        )
                      }
                      className="timer"
                      size="small"
                    />
                  </div>
                  {/*  ROW 5 */}
                  <p
                    className="m-0 btn-skin btn-skin-modal"
                    onClick={() => handdleModalGoals(internalGoal[index])}
                  >
                    <span className="meta-button">Meta:</span>
                    {toHour(arrWeek.internalGoal[index]?.goalInfo)}{" "}
                  </p>
                </div>
                <div className="box-one-detail t-center b-gray">
                  {/*  ROW 1 */}
                  <div className="style-of-datail">
                    <p
                      className="m-0 font-weight-600 btn-skin"
                      onClick={() => {
                        setValue("3");
                        setOpenControlModal(true);
                      }}
                    >
                      {" "}
                      QC{" "}
                    </p>
                  </div>
                  {/*  ROW 2 */}
                  <div className="input-container">
                    <input
                      className="t-center font-weight-600"
                      defaultValue={day.qc}
                      onChange={(e) => (day.qc = e.target.value)}
                      type="text"
                      onBlur={() => updateWeek(day, "externalSpots")}
                    />
                  </div>
                  {/*  ROW 3 */}
                  <p
                    className="m-0 btn-skin btn-skin-modal"
                    onClick={() => handdleModalSpots(internalSpots[index])}
                  >
                    <span className="meta-button">Meta:</span>
                    {arrWeek.internalSpots[index]?.qc || 0}
                    {""}
                  </p>
                  {/*  ROW 4 */}
                  <div className="icon-hrs">
                    <TimePicker
                      defaultValue={dayjs(toHour(day?.goalQc || 0), format)}
                      inputReadOnly={true}
                      format={format}
                      showNow={false}
                      suffixIcon={""}
                      className="timer"
                      size="small"
                      onBlur={(e) =>
                        updateWeek(
                          { ...day, goalQc: toMinutes(e.target.value) },
                          "externalGoals"
                        )
                      }
                    />
                  </div>
                  {/* <input
                    className="t-center font-weight-600"
                    defaultValue={day.goalQc}
                    onChange={(e) => (day.goalQc = e.target.value)}
                    type="text"
                    onBlur={() => updateWeek(day, "externalGoals")}
                  /> */}

                  {/*  ROW 5 */}
                  <p
                    className="m-0 btn-skin btn-skin-modal"
                    onClick={() => handdleModalGoals(internalGoal[index])}
                  >
                    <span className="meta-button">Meta:</span>{" "}
                    {toHour(arrWeek.internalGoal[index]?.goalQc)}{" "}
                  </p>
                </div>
                <div className="box-one-detail t-center b-gray">
                  {/*  ROW 1 */}
                  <div className="style-of-datail blue no-hover">
                    <p
                      className="m-0 font-weight-600 btn-skin no-cursor"
                      // onClick={() => {
                      //   setValue("1");
                      //   setOpenControlModal(true);
                      // }}
                    >
                      {" "}
                      TOTAL{" "}
                    </p>
                  </div>
                  {/*  ROW 2 */}
                  <div className="input-container">
                    <input
                      className="t-center font-weight-600 blue no-cursor"
                      defaultValue={day.total}
                      disabled
                      type="text"
                    />
                  </div>
                  {/*  ROW 3 */}
                  <p className="m-0 btn-skin blue no-cursor btn-skin-modal">
                    <span className="meta-button">Meta:</span>
                    {arrWeek.internalSpots[index]?.sumTotalInternalSpots || 0}
                    {""}
                  </p>
                  {/*  ROW 4 */}
                  <div className="icon-hrs">
                    <TimePicker
                      defaultValue={dayjs(toHour(day?.totalGoal || 0), format)}
                      inputReadOnly={true}
                      className="t-center font-weight-600 blue timer"
                      format={format}
                      suffixIcon={""}
                      showNow={false}
                      disabled={true}
                      style={{
                        backgroundColor: "#e9f4f3",
                      }}
                      size="small"
                    />
                  </div>
                  {/*  ROW 5 */}
                  <p className="m-0 btn-skin blue no-cursor btn-skin-modal">
                    <span className="meta-button">Meta:</span>{" "}
                    {toHour(arrWeek.internalGoal[index]?.sumTotalInternalGoals)}{" "}
                  </p>
                </div>
              </div>
            </>
          </div>
        ))}
        {/* //TOTAL DA SEMANA */}
        <div className="box-week">
          <div className="score-top t-center">
            <p className="transparent"> .</p>
            <p className="m-0 font-weight-600"> TOTAL DA SEMANA </p>
            <p className="transparent"> .</p>
          </div>
          <div className="detail-week">
            <div className="box-one-detail t-center b-gray no-cursor">
              {/*  ROW 1 */}
              <div className="style-of-datail">
                <p
                  className="m-0 font-weight-600 btn-skin no-cursor"
                  // onClick={() => {
                  //   setValue("1");
                  //   setOpenControlModal(true);
                  // }}
                >
                  {" "}
                  CAD{" "}
                </p>
              </div>
              {/*  ROW 2 */}
              <div className="input-container no-cursor">
                <input
                  className="t-center font-weight-600 no-cursor"
                  defaultValue={arrWeek.lastCad}
                  disabled
                  type="text"
                />
              </div>
              {/*  ROW 3 */}
              <p className="m-0 btn-skin no-cursor btn-skin-modal">
                <span className="meta-button">Meta:</span>
                {arrWeek.lastSpotsInternalCad || 0}
                {""}
              </p>
              {/*  ROW 4 */}
              <div className="icon-hrs">
                <TimePicker
                  defaultValue={dayjs(
                    toHour(arrWeek?.lastGoalCad || 0),
                    format
                  )}
                  inputReadOnly={true}
                  suffixIcon={""}
                  format={format}
                  showNow={false}
                  className="timer"
                  size="small"
                  disabled={true}
                />
              </div>
              {/*  ROW 5 */}
              <p className="m-0 btn-skin no-cursor btn-skin-modal">
                <span className="meta-button">Meta: </span>
                {arrWeek.lastGoalsInternalCad > 0
                  ? toHour(arrWeek.lastGoalsInternalCad)
                  : toHour(0)}
                {""}
              </p>
            </div>
            <div className="box-one-detail t-center b-gray">
              {/*  ROW 1 */}
              <div className="style-of-datail">
                <p
                  className="m-0 font-weight-600 btn-skin no-cursor"
                  // onClick={() => {
                  //   setValue("2");
                  //   setOpenControlModal(true);
                  // }}
                >
                  {" "}
                  INFO{" "}
                </p>
              </div>
              {/*  ROW 2 */}
              <div className="input-container no-cursor">
                <input
                  className="t-center font-weight-600 no-cursor"
                  defaultValue={arrWeek.lastInfo}
                  disabled
                  type="text"
                />
              </div>
              {/*  ROW 3 */}
              <p className="m-0 btn-skin no-cursor btn-skin-modal">
                <span className="meta-button">Meta:</span>
                {arrWeek.lastSpotsInternalInfo || 0}
              </p>
              {/*  ROW 4 */}
              <div className="icon-hrs">
                <TimePicker
                  defaultValue={dayjs(
                    toHour(arrWeek?.lastGoalInfo || 0),
                    format
                  )}
                  inputReadOnly={true}
                  format={format}
                  suffixIcon={""}
                  showNow={false}
                  className="timer"
                  size="small"
                  disabled={true}
                />
              </div>
              {/*  ROW 5 */}
              <p className="m-0 btn-skin no-cursor btn-skin-modal">
                <span className="meta-button">Meta:</span>
                {arrWeek.lastGoalsInternalInfo
                  ? toHour(arrWeek.lastGoalsInternalInfo)
                  : toHour(0)}
                {""}
              </p>
            </div>
            <div className="box-one-detail t-center b-gray">
              {/*  ROW 1 */}
              <div className="style-of-datail no-cursor">
                <p
                  className="m-0 font-weight-600 btn-skin no-cursor"
                  // onClick={() => {
                  //   setValue("3");
                  //   setOpenControlModal(true);
                  // }}
                >
                  {" "}
                  QC{" "}
                </p>
              </div>
              {/*  ROW 2 */}
              <div className="input-container no-cursor">
                <input
                  className="t-center font-weight-600 no-cursor"
                  defaultValue={arrWeek.lastQc}
                  disabled
                  type="text"
                />
              </div>
              {/*  ROW 3 */}
              <p className="m-0 btn-skin no-cursor btn-skin-modal">
                <span className="meta-button">Meta:</span>
                {arrWeek.lastSpotsInternalQc || 0}
              </p>
              {/*  ROW 4 */}
              <div className="icon-hrs">
                <TimePicker
                  defaultValue={dayjs(toHour(arrWeek?.lastGoalQc || 0), format)}
                  inputReadOnly={true}
                  format={format}
                  suffixIcon={""}
                  showNow={false}
                  className="timer"
                  size="small"
                  disabled={true}
                />
              </div>
              {/*  ROW 5 */}
              <p className="m-0 btn-skin no-cursor btn-skin-modal">
                <span className="meta-button">Meta:</span>
                {arrWeek.lastGoalsInternalQc
                  ? toHour(arrWeek.lastGoalsInternalQc)
                  : toHour(0)}
                {""}
              </p>
            </div>
            <div className="box-one-detail t-center b-gray">
              {/*  ROW 1 */}
              <div className="style-of-datail blue no-hover">
                <p
                  className="m-0 font-weight-600 btn-skin no-cursor"
                  // onClick={() => {
                  //   setValue("1");
                  //   setOpenControlModal(true);
                  // }}
                >
                  {" "}
                  TOTAL{" "}
                </p>
              </div>
              {/*  ROW 2 */}
              <div className="input-container no-cursor">
                <input
                  className="t-center font-weight-600 blue no-cursor"
                  defaultValue={arrWeek.lastTotal}
                  disabled
                  type="text"
                />
              </div>
              {/*  ROW 3 */}
              <p className="m-0 btn-skin blue no-cursor btn-skin-modal">
                <span className="meta-button">Meta:</span>
                {arrWeek.lastWeekSumTotalInternalSpots || 0}
              </p>
              {/*  ROW 4 */}
              <div className="icon-hrs">
                <TimePicker
                  defaultValue={dayjs(
                    toHour(arrWeek?.lastTotalGoal || 0),
                    format
                  )}
                  suffixIcon={""}
                  inputReadOnly={true}
                  className="t-center font-weight-600 blue timer"
                  format={format}
                  showNow={false}
                  disabled={true}
                  style={{
                    backgroundColor: "#e9f4f3",
                  }}
                  size="small"
                />
              </div>
              {/*  ROW 5 */}
              <p className="m-0 btn-skin blue no-cursor btn-skin-modal">
                <span className="meta-button">Meta: </span>
                {toHour(arrWeek.lastWeekSumTotalInternalGoals) || 0}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleUpdateTable = useCallback((updatedData) => {
    // Atualiza a tabela com os novos dados
    setWeek(updatedData.daysWeek);
    setPunctuation(updatedData);
  }, []);

  document.body.style.overflowY = "scroll";
  document.body.style.overflowX = "hidden";

  return (
    <>
      <ModalControl
        open={openControlModal}
        onClose={() => setOpenControlModal(false)}
        valueTab={value}
      />
      <div className="topo-quadros">
        <div className="title-quadro">
          <h3 className="title-table m-r10 m-b0"> CONTROLE DOS ESTUDOS</h3>
          <ModalActions
            open={openActionsModal}
            onClose={() => setOpenActionsModal(false)}
            calendar={calendarId}
          />
        </div>
        <ModalMetas
          open={openGoals}
          onClose={() => handleCloseGoals() /* setOpenGoals(false) */}
          internalGoal={currentGoalsSelected}
          punctuation={punctuation}
        />
        <ModalSpots
          open={openSpots}
          onClose={() => handleCloseSpots() /* setOpenSpots(false) */}
          internalSpots={currentSpotsSelected}
          punctuation={punctuation}
          onUpdateTable={handleUpdateTable}
        />
      </div>
      <section className="box-score">
        <span className="tip info-box-score one-tip">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 50, hide: 50 }}
            overlay={tipsPopoverOne}
          >
            <span> ? </span>
          </OverlayTrigger>
        </span>
        <span className="tip info-box-score two-tip">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 50, hide: 50 }}
            overlay={tipsPopoverTwo}
            style={{ width: "100%" }}
          >
            <span> ? </span>
          </OverlayTrigger>
        </span>
        <span className="tip info-box-score three-tip">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 50, hide: 50 }}
            overlay={tipsPopoverThree}
          >
            <span> ? </span>
          </OverlayTrigger>
        </span>
        <span className="tip info-box-score four-tip">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 50, hide: 50 }}
            overlay={tipsPopoverFour}
          >
            <span> ? </span>
          </OverlayTrigger>
        </span>
        <span className="tip info-box-score five-tip">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 50, hide: 50 }}
            overlay={tipsPopoverFive}
          >
            <span> ? </span>
          </OverlayTrigger>
        </span>
        <PunctuationWeek />
        <button
          className="btn btn-primary btnhover actions-score m-0"
          onClick={() => {
            setOpenActionsModal(true);
          }}
        >
          RESET
        </button>{" "}
      </section>

      <section>
        <CAccordion className="m-t100">
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              <strong>HISTÓRICO DAS PONTUAÇÕES SEMANAIS</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <div className="lines-of-table">
                {historic.length < 1 && (
                  <p className="notExists"> Nenhum histórico encontrado. </p>
                )}
                {historic &&
                  historic.records?.map((data, index) => (
                    <div key={index} className="historic-table">
                      <p className="m-b0 m-r10 historic_line">
                        <p className="m-0">
                          De {moment(data.startDay).format("DD/MM/YYYY")} a{" "}
                          {moment(data.endDay).format("DD/MM/YYYY")}:
                        </p>
                        <span>{data.total} pontos</span>
                      </p>
                    </div>
                  ))}
                <div className="pagination-my-books">
                  <Stack spacing={2}>
                    <Pagination
                      count={historic.pages}
                      page={currentPage}
                      onChange={(event, page) => handlePagination(page)}
                    />
                  </Stack>
                </div>
              </div>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </section>
    </>
  );
}
